:root {
    --header-height: 5vh;
    --main-body-height: 100vh;
    --nav-width: 68px;

    --primary-color: rgb(28, 189, 207);
    --primary-color-50: rgba(28, 189, 207, 0.5);
    --primary-color-30: rgba(28, 189, 207, 0.3);
    --primary-color-20: rgba(28, 189, 207, 0.2);
    --primary-color-15: rgba(28, 189, 207, 0.15);
    --info-color: rgb(61, 68, 101);
    --first-color: rgb(56, 63, 69);
    --first-color-alt: rgb(128, 143, 146);
    --white-color: rgb(211, 214, 219);
    --white-color-50: rgba(211, 214, 219, 0.5);
    --white-color-20: rgba(211, 214, 219, 0.2);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}

.main {
    position: relative;
    /* margin: var(--header-height) 0 0 0; */
    /* padding: 0 1rem; */
    font-size: 1rem;
    transition: 0.5s;
    min-height: var(--main-body-height);
    background-color: #eef2f6;
}

.main-with-header {
    padding-top: calc(var(--header-height) + 1vh);
}

.row,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto,
.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl,
.col-xxl-auto {
    margin: unset;
    padding: unset;
}

.container,
.container-fluid {
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 3vh;
    padding-bottom: 3vh;
}

.body-wrapper {
    display: flex;
    justify-content: center;
}

.scf-accordion {
    box-shadow: 0 10px 30px 0 rgba(172, 168, 168, 0.43);
}

.scf-accordion-body {
    padding: 5px 20px 0 20px;
}

.scf-card {
    border: 0;
    border-radius: 25px;
    box-shadow: 0 10px 30px 0 rgba(172, 168, 168, 0.43);
    background-color: #ebeef6;
    padding: 40px;
}

.scf-cancel-btn {
    height: 40px;
    margin: 10px 0 !important;
    line-height: 17px !important;
    font-weight: bold !important;
}

.scf-btn {
    height: 40px;
    margin: 10px 0 !important;
    line-height: 17px !important;
    color: white !important;
    font-weight: bold !important;
    background-color: var(--primary-color) !important;
}

.scf-btn:disabled {
    background-color: #cfd1d8 !important;
    color: #999ba0 !important;
}

/* Dialog Model */

.scf-modal {
    position: relative;
}

.scf-btn-modal-close {
    box-shadow: none !important;
    position: absolute !important;
    right: 0px;
    top: 0px;
    display: flex !important;
    align-items: center;
}

.scf-modal-row {
    display: flex;
    justify-content: center;
}

.scf-page-title--failure {
    color: #e02020 !important;
}

.scf-page-payment-title {
    text-align: center;
    color: var(--primary-color);
    font-size: 2rem !important;
    font-weight: bold !important;
}

.scf-page-title {
    text-align: center;
    color: var(--primary-color);
    font-size: 1.75rem !important;
    font-weight: bold !important;
}

.scf-page-title--success {
    color: #008567 !important;
}

.scf-page-message {
    text-align: center;
    font-size: large !important;
}

.scf-status-syb {
    font-size: 72px;
    padding: 5px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.scf-status-syb--failure {
    border: 3px solid #e02020;
    color: #e02020;
}

.scf-status-syb__stop-icon {
    border: 3px solid #f7b500;
    color: #f7b500;
}

.scf-status-syb--success {
    border: 3px solid #008567;
    color: #008567;
}

/* table */
.scf-table-container {
    padding: 0;
}

.scf-table {
    min-width: 650px;
    background-color: transparent;
    overflow-x: auto;
}

.scf-table-pagination {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center !important;
}

.scf-table th {
    font-weight: bold !important;
    background-color: var(--info-color);
    color: var(--white-color) !important;
}

.scf-table-status {
    font-weight: bold !important;
    font-size: 0.75rem !important;
    color: white !important;
    border-radius: 8px;
    padding: 3px 10px;
    display: inline-block;
}

@media screen and (min-width: 768px) {
    .main-with-sidebar {
        margin-left: var(--nav-width);
    }
}

.scf-card-iframe {
    overflow: hidden;
    pointer-events: none;
    zoom: 0.4;
    transform: scale(0.4);
    transform-origin: 0 0;
    -ms-zoom: 0.4;
    -moz-transform: scale(0.4);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.4);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.4);
    -webkit-transform-origin: 0 0;
}

/*footer*/

.footer-59391 {
    border-top: 1px solid #efefef;
    background-color: #fff;
    font-family: "Poppins", sans-serif;
    padding: 4rem 0;
}

.footer-59391 .site-logo {
    color: #fff;
}

.footer-59391 .site-logo a {
    font-size: 30px;
    color: #000;
    font-weight: 900;
}

.footer-59391 .social-icons li {
    margin: 3px;
    display: inline-block;
}

.footer-59391 .social-icons li a {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.footer-59391 .social-icons li a.dr {
    background: #ff2e6e;
}

.footer-59391 .social-icons li a.be {
    background: #394cff;
}

.footer-59391 .social-icons li a.tw {
    background: #00a0fb;
}

.footer-59391 .social-icons li a.in {
    background: #c31574;
}

.footer-59391 .social-icons li a.fb {
    background: #3b579b;
}

.footer-59391 .social-icons li a.yt {
    background: #fa2614;
}

.footer-59391 .social-icons li a span {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #fff;
}

.footer-59391 .nav-links li {
    display: inline-block;
}

.footer-59391 .nav-links li a {
    font-size: 14px;
    color: #777;
    padding: 10px;
}

@media (max-width: 1199.98px) {
    .footer-59391 .nav-links.nav-left li:first-child a {
        padding-left: 0;
    }
}

.footer-59391 .nav-links.nav-right li:last-child a {
    padding-right: 0;
}

@media (max-width: 1199.98px) {
    .footer-59391 .nav-links.nav-right li:first-child a {
        padding-left: 0;
    }
}

.footer-59391 .copyright {
    border-top: 1px solid #efefef;
    padding-top: 50px;
    text-align: center;
    color: #777;
}

/*403 error*/

.forbidden .body{
    min-height: var(--main-body-height);
    min-width: 100vw;
    background: #233142;

}
.forbidden .whistle{
    width: 20%;
    fill: #f95959;
    margin: 100px 40%;
    text-align: left;
    transform: translate(-50%, -50%);
    transform: rotate(0);
    transform-origin: 80% 30%;
    animation: wiggle .2s infinite;
}

@keyframes wiggle {
    0%{
        transform: rotate(3deg);
    }
    50%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(3deg);
    }
}
.forbidden h1{
    margin-top: -100px;
    margin-bottom: 20px;
    color: #facf5a;
    text-align: center;
    font-family: 'Raleway';
    font-size: 90px;
    font-weight: 800;
}
.forbidden h2{
    color: #455d7a;
    text-align: center;
    font-family: 'Raleway';
    font-size: 30px;
    text-transform: uppercase;
}

.forbidden .btn-back {
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    height: 5vmin;
    padding: 12px;
    font-family: "Raleway", sans-serif;
    text-decoration: none;
    border-radius: 5px;
}
.forbidden .btn-back:hover {
    background: var(--primary-color);
    color: var(--white-color);
}
@media (max-width: 480px) and (min-width: 320px) {
    .forbidden .btn-back {
        font-size: 3.5vmin;
    }
}